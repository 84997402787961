import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { Box, Grid, TablePagination, TableSortLabel } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Helmet } from 'react-helmet';
import { getBasketFromLocalStorage, saveBasketToLocalStorage } from "Utilities/localStorage";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Link from '@mui/material/Link';

const ProductsTable = ({ columns, filteredData }) => {
  const theme = useTheme();
  const [expandedRow, setExpandedRow] = useState(null);
  const [basket, setBasket] = useState(getBasketFromLocalStorage());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [open, setOpen] = useState(false);
  
  const renderFilterName = (name) => {
      return name.split(/(<sub>.*?<\/sub>)/g).map((part, index) => {
        if (part.startsWith("<sub>") && part.endsWith("</sub>")) {
          return <sub key={index}>{part.replace(/<\/?sub>/g, "")}</sub>;
        }
        return part;
      });
    };
    

  useEffect(() => {
    saveBasketToLocalStorage(basket);
  }, [basket]);

  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const handleToggleQuote = (item, event) => {
    event.stopPropagation();
    const itemCode = item.itemcode;
    setBasket((prevBasket) => {
      const newBasket = { ...prevBasket };
      if (newBasket[itemCode]) {
        delete newBasket[itemCode];
      } else {
        const { itemcode, params } = item;
        const details = params.ParametersUI;
        const description = params.Description;
        const selectedDetails = {};
        columns.filter((col) => col.isMain).forEach((col) => {
          selectedDetails[col.name] = details[col.name];
        });
        newBasket[itemCode] = { itemCode, details: selectedDetails, description };
        setOpen(true);
      }
      saveBasketToLocalStorage(newBasket);
      const basketEvent = new CustomEvent('basketChange', { detail: newBasket });
      window.dispatchEvent(basketEvent);
      return newBasket;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (column) => {
    // Only allow sorting if the column has a multiplier
    if (column.multipler) {
      const isAsc = orderBy === column.name && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(column.name);
    }
  };

  const applyMultiplier = (value, column) => {
    const multiplier = column.multipler || 1;
    return parseFloat(value) * multiplier;
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (!orderBy) return 0;

    const column = columns.find(col => col.name === orderBy);

    const aValue = applyMultiplier(a.params.ParametersUI[orderBy] || 0, column);
    const bValue = applyMultiplier(b.params.ParametersUI[orderBy] || 0, column);

    if (order === 'asc') {
      return aValue - bValue;
    } else {
      return bValue - aValue;
    }
  });

  const renderHeader = () => (
    <TableHead>
      <TableRow 
        sx={{ 
          bgcolor: "alternate.light",
          '& th': {
            padding: '16px 12px',
            borderBottom: '2px solid rgba(224, 224, 224, 0.8)',
            transition: 'background-color 0.2s ease',
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.02)'
            }
          }
        }}
      >
        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 0.8)' }}>
          <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase", letterSpacing: '0.5px' }}>
            ItemCode
          </Typography>
        </TableCell>
        {columns &&
          columns.filter((col) => col.isMain).map((column) => (
            <TableCell
              sx={{ borderRight: '1px solid rgba(224, 224, 224, 0.8)' }}
              key={column.name}
              sortDirection={orderBy === column.name ? order : false}
            >
              {column.multipler ? (
                <TableSortLabel
                  active={orderBy === column.name}
                  direction={orderBy === column.name ? order : 'asc'}
                  onClick={() => handleSortRequest(column)}
                  sx={{
                    '&.MuiTableSortLabel-active': {
                      color: 'primary.main',
                      '& .MuiTableSortLabel-icon': {
                        color: 'primary.main'
                      }
                    }
                  }}
                >
                  <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase", letterSpacing: '0.5px' }}>
                    {renderFilterName(column.name)}
                  </Typography>
                </TableSortLabel>
              ) : (
                <Typography variant={"caption"} fontWeight={700} sx={{ textTransform: "uppercase", letterSpacing: '0.5px' }}>
                  {renderFilterName(column.name)}
                </Typography>
              )}
            </TableCell>
          ))}
        <TableCell sx={{ borderRight: '1px solid rgba(224, 224, 224, 0.8)' }} />
      </TableRow>
    </TableHead>
  );

  const renderCell = (item) => (
    <>
      <TableCell 
        sx={{ 
          borderRight: '1px solid rgba(224, 224, 224, 0.8)',
          padding: '16px 12px',
          transition: 'all 0.2s ease'
        }} 
        component="th" 
        scope="row"
      >
        <Typography 
          variant={"subtitle2"} 
          fontWeight={700}
          sx={{
            color: 'text.primary',
            fontSize: '0.875rem'
          }}
        >
          {item.itemcode}
        </Typography>
      </TableCell>
      {columns &&
        columns.filter((col) => col.isMain).map((column) => (
          <TableCell 
            sx={{ 
              borderRight: '1px solid rgba(224, 224, 224, 0.8)',
              padding: '16px 12px',
              transition: 'all 0.2s ease'
            }} 
            component="th" 
            scope="row" 
            key={column.name}
          >
            <Typography 
              variant={"subtitle2"} 
              sx={{
                color: 'text.secondary',
                fontSize: '0.875rem',
                fontWeight: 500
              }}
            >
              {(item && item.params && item.params.ParametersUI) ? (item.params.ParametersUI[column.name] ?? "") : 'No Data'}
            </Typography>
          </TableCell>
        ))}
    </>
  );

  const renderBody = () => (
    sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
      <React.Fragment key={i}>
        <TableRow
          onClick={() => handleRowClick(i)}
          sx={{ 
            cursor: "pointer",
            transition: 'all 0.2s ease',
            "&:last-child td, &:last-child th": { border: 0 },
            bgcolor: basket[item.itemcode] 
              ? "primary.light" 
              : expandedRow === i 
                ? "alternate.light" 
                : "alternate.dark",
            backgroundImage: basket[item.itemcode] 
              ? "linear-gradient(90deg, rgba(220, 240, 255, 0.5) 0%, rgba(180, 210, 255, 0.3) 100%)" 
              : "none",
            '&:hover': {
              bgcolor: basket[item.itemcode]
                ? 'primary.light'
                : 'rgba(0, 0, 0, 0.02)'
            }
          }}        
        >
          {renderCell(item)}
          <TableCell 
            sx={{
              borderRight: '1px solid rgba(224, 224, 224, 0.8)',
              width: '180px', 
              minHeight: '60px', 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '16px 12px',
              transition: 'all 0.2s ease'
            }}
          >
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', 
                width: '100%',
                padding: '8px', 
                textAlign: 'center',
                whiteSpace: 'nowrap',
                transition: 'all 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-1px)'
                }
              }}
            >
              <Typography
                color={"primary"}
                variant={"subtitle2"}
                fontWeight={700}
                sx={{
                  cursor: "pointer",
                  display: "inline-block",
                  fontSize: '0.875rem',
                  padding: '8px 16px',
                  borderRadius: '6px',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    bgcolor: 'rgba(25, 118, 210, 0.08)'
                  }
                }}
                onClick={(event) => handleToggleQuote(item, event)}
              >
                {basket[item.itemcode] 
                  ? "Remove From Quote" 
                  : "Add To Quote"
                }
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell 
            sx={{ 
              borderRight: '1px solid rgba(224, 224, 224, 0.8)',
              padding: 0,
              bgcolor: 'rgba(0, 0, 0, 0.02)'
            }} 
            colSpan={6969}
          >
            <Collapse in={expandedRow === i} timeout="auto" unmountOnExit>
              <ProductDetailsEnhanced item={item} columns={columns} />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ))
  );

  return (
    <>
      <Helmet>
        <title>Products - Our Offerings</title>
        <meta name="description" content="Browse our extensive list of products. Click on any product to see more details and add it to your quote list." />
      </Helmet>
      <TableContainer 
        component={Paper} 
        sx={{ 
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
          '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '0',
          }
        }}
      >
        <Table sx={{ minWidth: 750 }} aria-label="Product Table">
          {renderHeader()}
          <TableBody>{renderBody()}</TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            borderTop: '1px solid rgba(224, 224, 224, 0.4)',
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              fontSize: '0.875rem',
              color: 'text.secondary',
              fontWeight: 500
            }
          }}
        />
        <Snackbar
          open={open}
          autoHideDuration={2500}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          disableWindowBlurListener
          sx={{
            '& .MuiSnackbarContent-root': {
              backgroundColor: theme.palette.success.main,
              color: theme.palette.success.contrastText,
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.9
              },
              zIndex: 1000,
              pointerEvents: 'auto'
            },
            zIndex: 1000,
            pointerEvents: 'none'
          }}
        >
          <Alert 
            onClose={handleClose} 
            severity="success" 
            sx={{ 
              width: "100%", 
              marginTop: "80px",
              cursor: 'pointer',
              '& .MuiAlert-message': {
                fontSize: '0.9rem',
                fontWeight: 500
              },
              pointerEvents: 'auto'
            }}
            onClick={handleClose}
          >
            We added item to the quote, please click "Send Inquiry" when you ready to proceed!
          </Alert>
        </Snackbar>
      </TableContainer>
    </>
  );
};

const ProductDetailsEnhanced = ({ item, columns }) => {
  const itemDetails = item.params;

  const getGroupedColumns = (cols) => {
    const chunkSize = Math.ceil((cols.length - 1) / 2);
    return [cols.slice(1, chunkSize + 1), cols.slice(chunkSize + 1)];
  };

  const groupedColumns = getGroupedColumns(columns);

  return (
    <Box 
      sx={{ 
        padding: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.02)'
      }}
    >
      <Grid container spacing={3}>
        {/* Description and Image */}
        <Grid item xs={12} md={3}>
          <Box 
            sx={{
              height: '100%',
              backgroundColor: 'white',
              borderRadius: '12px',
              padding: 3,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
            }}
          >
            <Typography 
              variant="subtitle1" 
              fontWeight={600}
              sx={{ 
                color: 'text.primary',
                marginBottom: 2
              }}
            >
              Product Overview
            </Typography>
            <Typography 
              variant="body1" 
              sx={{
                color: 'text.primary',
                fontWeight: 500,
                lineHeight: 1.6,
                marginBottom: 3
              }}
            >
              {itemDetails["Description"]}
            </Typography>
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                borderRadius: '8px',
                padding: 2,
                minHeight: '200px'
              }}
            >
              {itemDetails["ImageUrl"] ? (
                <img
                  src={itemDetails["ImageUrl"]}
                  alt={itemDetails["PartNumber"]}
                  style={{ 
                    maxWidth: "100%", 
                    maxHeight: "200px",
                    objectFit: 'contain'
                  }}
                />
              ) : (
                <img
                  src={"https://th.bing.com/th/id/OIP.gLIxUrBNj4TE3OEKt5N-2wHaGO?rs=1&pid=ImgDetMain"}
                  alt={itemDetails["PartNumber"]}
                  style={{ 
                    maxWidth: "100%", 
                    maxHeight: "200px",
                    objectFit: 'contain'
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>

        {/* Technical Specifications Group 1 */}
        <Grid item xs={12} md={3}>
          <Box 
            sx={{
              height: '100%',
              backgroundColor: 'white',
              borderRadius: '12px',
              padding: 3,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
            }}
          >
            <Typography 
              variant="subtitle1" 
              fontWeight={600}
              sx={{ 
                color: 'text.primary',
                marginBottom: 2
              }}
            >
              Technical Specifications 1
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {groupedColumns[0].map((column) => (
                <Box
                  key={column.name}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5
                  }}
                >
                  <Typography
                    sx={{ 
                      color: 'text.primary',
                      fontWeight: 500,
                      fontSize: '0.875rem'
                    }}
                  >
                    {column.name}
                  </Typography>
                  <Typography
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                      wordBreak: 'break-word'
                    }}
                  >
                    {itemDetails.ParametersUI[column.name] ?? "No data"}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Technical Specifications Group 2 */}
        <Grid item xs={12} md={3}>
          <Box 
            sx={{
              height: '100%',
              backgroundColor: 'white',
              borderRadius: '12px',
              padding: 3,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
            }}
          >
            <Typography 
              variant="subtitle1" 
              fontWeight={600}
              sx={{ 
                color: 'text.primary',
                marginBottom: 2
              }}
            >
              Technical Specifications 2
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {groupedColumns[1].map((column) => (
                <Box
                  key={column.name}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5
                  }}
                >
                  <Typography
                    sx={{ 
                      color: 'text.primary',
                      fontWeight: 500,
                      fontSize: '0.875rem'
                    }}
                  >
                    {column.name}
                  </Typography>
                  <Typography
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                      wordBreak: 'break-word'
                    }}
                  >
                    {itemDetails.ParametersUI[column.name] ?? "No data"}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Documentation */}
        <Grid item xs={12} md={3}>
          <Box 
            sx={{
              height: '100%',
              backgroundColor: 'white',
              borderRadius: '12px',
              padding: 3,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
            }}
          >
            <Typography 
              variant="subtitle1" 
              fontWeight={600}
              sx={{ 
                color: 'text.primary',
                marginBottom: 2
              }}
            >
              Documentation
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {itemDetails["Certificates"] && (
                <Box>
                  <Typography 
                    sx={{ 
                      display: "flex", 
                      fontSize: "0.875rem",
                      color: 'text.primary',
                      fontWeight: 500,
                      alignItems: 'center',
                      marginBottom: 1.5
                    }} 
                  >
                    <FileDownloadIcon sx={{ fontSize: "1.25rem", marginRight: "8px" }} />
                    Certificates
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {itemDetails["Certificates"].map((cert) => (
                      <Link
                        key={cert.label}
                        href={cert.src}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '8px 12px',
                          backgroundColor: 'rgba(0, 0, 0, 0.02)',
                          borderRadius: '8px',
                          color: 'primary.main',
                          textDecoration: 'none',
                          fontSize: '0.875rem',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {cert.label}
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}
              {itemDetails["DataSheets"] && (
                <Box>
                  <Typography 
                    sx={{ 
                      display: "flex", 
                      fontSize: "0.875rem",
                      color: 'text.primary',
                      fontWeight: 500,
                      alignItems: 'center',
                      marginBottom: 1.5
                    }} 
                  >
                    <FileDownloadIcon sx={{ fontSize: "1.25rem", marginRight: "8px" }} />
                    DataSheets
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {itemDetails["DataSheets"].map((file) => (
                      <Link
                        key={file.label}
                        href={file.src}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '8px 12px',
                          backgroundColor: 'rgba(0, 0, 0, 0.02)',
                          borderRadius: '8px',
                          color: 'primary.main',
                          textDecoration: 'none',
                          fontSize: '0.875rem',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {file.label}
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductsTable;
