import { useState, useEffect } from 'react';
import { getCategoriesApi } from '../API/tableAPI/groupAPI';


const useCategories = (categoryName) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getCategoriesApi(categoryName);
            setData(response);
        }   
        fetchData(); 

    }, [categoryName]);

    return data;
};

export default useCategories;