import axios from "axios";
import config from '../../config/config';

const getCategoriesApi = async (categoryName) => {
    const response = await axios.get(`${config.API_BASE_URL}/groups${categoryName ? `?category=${categoryName}` : ''}`);
    return response.data;
}

const getBreadcrumbsApi = async (groupId) => {
    const response = await axios.get(`${config.API_BASE_URL}/group/${groupId}/breadcrumbs`);
    return response.data;
}

export { getCategoriesApi, getBreadcrumbsApi };