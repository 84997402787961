// FiltersWithDropdown.js

import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { UberFilterGroup } from './UberFilter';

const FiltersWithDropdown = ({
  name,
  handleSearchChange,
  mockData,
  splashImage,
  siblings,
  categories,
  filteredData,
  filters,
  handleFilterApply,
  partNumber,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const categoryId = categories.filter((category) => category.name === name)[0].id;
  const parentCategory = categories.find((category) =>
    category.childrenIds.some((child) => child.id === categoryId)
  );

  const [selectedCategory, setSelectedCategory] = useState(name);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    setSelectedCategory(name);
    clearAllFilters();
    if (partNumber) {
      handleApply([
        {
          columnName: 'PartNumber',
          value: partNumber,
          operator: 'eq',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const getFilterLabel = (filter) => {
    const columnName = filter.columnName.split('.').pop();
    const control = filters.filterGroups
      .flatMap((group) => group.controls)
      .find((ctrl) => ctrl.column === columnName);

    if (!control) {
      return `${columnName}: ${filter.value}`;
    }

    const unit = control.unit ? ` ${control.unit}` : '';
    const multipler = control.multipler ?? 1;

    let displayValue;
    if (control.filterType === 'checkboxlist') {
      // Do not apply multipler for checkbox values
      displayValue = Array.isArray(filter.value) ? filter.value.join(', ') : filter.value;
    } else {
      // Reconstruct the unmultiplied value
      if (Array.isArray(filter.value)) {
        displayValue = filter.value.map((v) => v / multipler).join(', ');
      } else {
        displayValue = filter.value / multipler;
      }
    }

    // Determine the operator symbol
    let operatorSymbol = '';
    switch (filter.operator) {
      case 'gte':
        operatorSymbol = '≥';
        break;
      case 'lte':
        operatorSymbol = '≤';
        break;
      case 'eq':
        operatorSymbol = '=';
        break;
      case 'in':
        operatorSymbol = '∈';
        break;
      default:
        operatorSymbol = filter.operator;
    }

    return `${columnName} ${operatorSymbol} ${displayValue}${unit}`;
  };

  const removeFilter = (filter) => {
    const newFilters = selectedFilters.filter(
      (item) =>
        item.columnName !== filter.columnName ||
        item.value !== filter.value ||
        item.operator !== filter.operator
    );
    setSelectedFilters(newFilters);
    handleFilterApply(newFilters);
    if (filter.columnName === 'Part Number') {
      navigate(`/products/${name}/list`);
    }
  };

  const clearAllFilters = () => {
    setSelectedFilters([]);
    handleFilterApply([]);
    navigate(`/products/${name}/list`);
  };

  const handleApply = (newFilters) => {
    const updatedFilters = [...selectedFilters];

    newFilters.forEach((newFilter) => {
      const existingFilterIndex = updatedFilters.findIndex(
        (filter) =>
          filter.columnName === newFilter.columnName && filter.operator === newFilter.operator
      );

      if (existingFilterIndex > -1) {
        updatedFilters[existingFilterIndex] = newFilter; // Update existing filter
      } else {
        updatedFilters.push(newFilter); // Add new filter
      }
    });

    setSelectedFilters(updatedFilters);
    handleFilterApply(updatedFilters);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/products/50,51')}
          style={{ cursor: 'pointer' }}
        >
          Products
        </Link>
        {parentCategory ? (
          <Link
            underline="hover"
            color="inherit"
            onClick={() =>
              navigate(`/products/${parentCategory.childrenIds.map((child) => child.id).join(',')}`)
            }
            style={{ cursor: 'pointer' }}
          >
            {parentCategory.name}
          </Link>
        ) : (
          <Typography color="text.secondary">Unknown Category</Typography>
        )}
        <Typography color="text.primary">{name}</Typography>
      </Breadcrumbs>

      {/* Image Component */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: '2%',
          width: '100%',
          height: 'auto',
          maxHeight: '30vh',
          objectFit: 'cover',
        }}
      >
        <Box
          component="img"
          sx={{
            width: 'auto',
            height: 'auto',
            maxHeight: '23vh',
            objectFit: 'cover',
          }}
          src={splashImage}
          alt="category.image"
        />
      </Box>

      {/* Category Navigation */}
      <Box 
        display="flex" 
        justifyContent="center" 
        marginBottom={4} 
        marginTop={4}
        sx={{
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        {siblings.map((sibling, index) => (
          <Link
            key={index}
            component="button"
            variant="body2"
            onClick={() => navigate(`/products/${sibling.name}/list`)}
            sx={{
              textDecoration: sibling.name === selectedCategory ? 'underline' : 'none',
              color: sibling.name === selectedCategory
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
              fontWeight: sibling.name === selectedCategory ? 'bold' : 'normal',
              fontSize: '1.1rem',
              padding: '8px 16px',
              borderRadius: '8px',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              backgroundColor: sibling.name === selectedCategory 
                ? 'rgba(25, 118, 210, 0.08)'
                : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.12)',
                transform: 'translateY(-1px)'
              }
            }}
          >
            {sibling.name}
          </Link>
        ))}
      </Box>

      {/* Filter Groups */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          marginBottom: 2,
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: 3,
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
        }}
      >
        <Typography 
          variant="subtitle1" 
          fontWeight={600}
          sx={{ 
            color: 'text.primary',
            marginBottom: 1
          }}
        >
          Filter Options
        </Typography>

        {/* Search Bar */}
        <Box 
          sx={{ 
            width: '100%',
            marginBottom: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              backgroundColor: 'white',
              '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main
                }
              }
            }
          }}
        >
          <Autocomplete
            freeSolo
            disableClearable
            options={filteredData.map((option) => option.params[filters && filters.global.name])}
            onInputChange={handleSearchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <Typography sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    color: 'text.secondary',
                    fontWeight: 500
                  }}>
                    <SearchIcon sx={{ marginRight: '0.5rem' }} />
                    Search
                  </Typography>
                }
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            maxHeight: '200px',
            overflowY: 'auto',
            paddingRight: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(0, 0, 0, 0.05)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.3)',
              },
            },
          }}
        >
          {filters &&
            filters.filterGroups.map((filterGroup) => (
              <Box key={filterGroup.name}>
                <UberFilterGroup
                  filteredData={filteredData}
                  filterGroup={filterGroup}
                  selectedFilters={selectedFilters}
                  callback={handleApply}
                />
              </Box>
            ))}
        </Box>
      </Box>

      {/* Selected Filters */}
      <Box 
        sx={{
          display: "flex", 
          flexWrap: "wrap", 
          gap: 1,
          padding: 2,
          backgroundColor: 'rgba(25, 118, 210, 0.04)',
          borderRadius: '12px',
          marginBottom: 2
        }}
      >
        {selectedFilters.map((filter, index) => (
          <Chip
            key={index}
            label={getFilterLabel(filter)}
            onDelete={() => removeFilter(filter)}
            deleteIcon={<CloseIcon />}
            sx={{
              backgroundColor: 'white',
              '& .MuiChip-deleteIcon': {
                color: 'text.secondary',
                '&:hover': {
                  color: 'error.main'
                }
              }
            }}
          />
        ))}
        {selectedFilters.length > 0 && (
          <Chip
            label="Clear all"
            onClick={clearAllFilters}
            variant="outlined"
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.08)'
              }
            }}
          />
        )}
      </Box>
    </>
  );
};

export default FiltersWithDropdown;
